import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Typography from '@mui/material/Typography';

import MenuPanelContent from 'v2/features/app-drawer/components/MenuPanelContent';
import ScrollableStack from 'v2/features/app-drawer/components/ScrollableStack/ScrollableStack';

import {ENDPOINTS} from '../../../../../shared/constants';
import DocumentLoadingButton from '../ToolsContent/DocumentLoadingButton';

const DOCUMENT_NAMES = {
  FaqCooperativeNL: 'Veelgestelde_vragen_en_antwoorden_coöperatie_DELA_NL.pdf',
  FaqCooperativeFR: 'Questions_et_réponses_fréquentes_coopérative_DELA_FR.pdf',
};

const ProductInfoContent = () => {
    const intl = useIntl();
    const faqLanguageQueryParam = intl.formatMessage({id: 'appDrawer.productInfo.4.link.faqLanguageQueryParam'});

    return <ScrollableStack spacing={3}>
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.1.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.1.content"/>}
        linkText={<FormattedMessage id="appDrawer.productInfo.1.link.text"/>}
        hrefId="appDrawer.productInfo.1.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.2.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.2.content"/>}
        linkText={<FormattedMessage id="appDrawer.productInfo.2.link.text"/>}
        hrefId="appDrawer.productInfo.2.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.3.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.3.content"/>}
        linkText={<FormattedMessage id="appDrawer.productInfo.3.link.text"/>}
        hrefId="appDrawer.productInfo.3.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.4.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.4.content"/>}
      >
        <DocumentLoadingButton
          reference={`${ENDPOINTS.cooperativeMembership}/faq?language=${faqLanguageQueryParam}`}
          label={<FormattedMessage id="appDrawer.productInfo.4.link.text"/>}
          name={faqLanguageQueryParam === 'fr' ? DOCUMENT_NAMES.FaqCooperativeFR : DOCUMENT_NAMES.FaqCooperativeNL}
        />
      </MenuPanelContent>
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.5.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.5.content"/>}
        linkText={<FormattedMessage id="appDrawer.productInfo.5.link.text"/>}
        hrefId="appDrawer.productInfo.5.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.6.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.6.content"/>}
        linkText={<FormattedMessage id="appDrawer.productInfo.6.link.text"/>}
        hrefId="appDrawer.productInfo.6.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.productInfo.7.title"/>}
        text={<FormattedMessage id="appDrawer.productInfo.7.content"/>}
        linkText={<FormattedMessage id="appDrawer.productInfo.7.link.text"/>}
        hrefId="appDrawer.productInfo.7.link.href"
      />
      <Typography variant="h6" fontWeight="bold" textAlign="center" marginTop={2}>
        <FormattedMessage id="appDrawer.productInfo.footer"/>
      </Typography>
    </ScrollableStack>
  }
;

export default ProductInfoContent;
